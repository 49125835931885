export default {
  props: {
    sHeight: {
      type: String,
      default:'0'
    },
    sMarginTop: {
      type: String,
      default: '0'
    },
  },
  data() {
    return {
      nameProfile: "",
      abbrNameProfile: "",
      texts: "",
      menuHeight: 0,
      menuHeightResp:0,
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
      fav: true,
      menu: false,
      message: false,
      hints: true,
      closeOnClick: false,
      activeCatalogGroup:false
    };
  },
  beforeMount() {
    this.texts = FILE.navbarTexts[this.selectLanguage];
    this.nameProfile = this.$store.state.sFullName;

    if (this.nameProfile.split(" ").length == 4) {
      this.abbrNameProfile =
        this.nameProfile.split(" ")[0].charAt(0) +
        this.nameProfile.split(" ")[2].charAt(0);
    } else if (this.nameProfile.split(" ").length == 3) {
      this.abbrNameProfile =
        this.nameProfile.split(" ")[0].charAt(0) +
        this.nameProfile.split(" ")[1].charAt(0);
    } else if (this.nameProfile.split(" ").length == 2) {
      this.abbrNameProfile =
        this.nameProfile.split(" ")[0].charAt(0) +
        this.nameProfile.split(" ")[1].charAt(0);
    }




  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      (window.innerWidth > 900)? this.menu = false: ''
      this.menuHeight = window.innerHeight  - 17;
      this.menuHeightResp = window.innerHeight  - 245;
    },
    killSession() {
      if (this.$store.state.sToken !== "") {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.delete(`${URI}/api/v1/${this.selectLanguage}/session`, config)
          .then((response) => {
            localStorage.clear();
            this.$store.commit("setToken", "");
            this.$router.push("/");
          })
          .catch((error) => {
                      this.mixError(error.response.data.message,error.response.status);
          });
      }
    },
    changeLanguage(val) {
      this.$store.commit("toggle_language", val);
    },
    setMenuSidebar() {
      var active = (this.$store.state.bMenuSidebar =
        !this.$store.state.bMenuSidebar);
      this.$store.commit("setMenuSidebar", {
        active: active,
      });
    },
  },
  computed: {
    bMenuSidebar() {

      if(this.$route.name == "Subcategories"){
        // const refCategoriesList = this.$refs.CategoriesList;
        this.activeCatalogGroup = true;
      }else{
        this.activeCatalogGroup = false;
      }  
      return this.$store.state.bMenuSidebar;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.navbarTexts[this.selectLanguage];
      }
    },
  },
};